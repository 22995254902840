import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import {HomeComponent} from './home/home.component';
import {NoPermissionComponent} from './authentication/no-permission/no-permission.component';
import {ChangePasswordComponent} from "./authentication/change-password/change-password.component";

export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: 'reports/balance-report',
        pathMatch: 'full'
      },
      {
        path: 'home',
        redirectTo: 'reports/balance-report'
      },
      {
        path: 'sysadmin',
        loadChildren: './sysadmin/sysadmin.module#SysadminModule'
      },
      {
        path: 'reports',
        loadChildren: './reports/reports.module#ReportsModule'
      },
      {
        path: 'authentication/change-password',
        component: ChangePasswordComponent
      },
      {
        path: 'authentication/403',
        component: NoPermissionComponent
      },
    ]
  },
  {
    path: '',
    component: AppBlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
            './authentication/authentication.module#AuthenticationModule'
      },
      {
        path: 'invite',
        loadChildren:
            './invite/invite.module#InviteModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'authentication/404'
  }
];
