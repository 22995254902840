import { Component, OnInit } from '@angular/core';
import {NotifierService} from 'angular-notifier';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
      private notifierService: NotifierService,
      private httpClient: HttpClient,
  ) { }

  ngOnInit() {
  }

}
